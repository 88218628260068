body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  margin: 5px;
  padding: 5px;
  list-style-type: none;
}

.govee-title {
  margin: 10px 5px 15px 25px;
}

.header {
  display: flex;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 20px;
}

.email-signin {
  /*display: flex;*/
  vertical-align: middle;
  align-self: center;
  margin-left: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 1 1;
}

.email-signin-row {
  display: flex;
  vertical-align: middle;
  margin-bottom: 5px;
}

.email-signout-row {
  margin-left: 10px;
  display: flex;
  vertical-align: middle;
}

.email-signin-error {
  color: red;
  font-weight: bold;
  margin-right: 10px;
  margin-bottom: 5px;
}

.email-signin-error-hide {
  display: none;
}

.email-signin-helper {
  display: flex;
  vertical-align: middle;
  margin-top: 20px;
  margin-bottom: 5px;
  margin-right: 15px;
  font-size: 1.3em;
}



.err-message-hide {
  display: none;
}

.err-message-show {
  display: block;
  color: red;
  font-weight: bold;
  font-size: 1.10em;
  margin-top: 15px;
  margin-left: 25px;
  margin-right: 25px;
  clear: left;
}

.device-header {
  padding: 0;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 98%;
  display: flex;
}

.device-header-name {
  float: left;
}

.device-header-labels {
  min-height: 36px;
}

.device-header-labels-container {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 190px;
}

.device-header-labels-name {
  float: left;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.device-header-labels-display-name {
  display: flex;
}

.device-header-labels-display-name input {
  width: 100px;
}

.device-header-labels-display-name-form {
  margin-left: 3px;
  cursor: pointer;
  color: #666666;
}

.device-header-labels-display-name-edit {
  margin-left: 4px;
  cursor: pointer;
  color: #888888;
}

.device-header-labels-byline {
  clear: left;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;
  unicode-bidi: isolate;
  margin: 0 4px 0 4px;
  float: left;
}

.device-header-labels-temperature {
  color: #ff780a;
}

.device-header-labels-humidity {
  color: #629af0;
  float: left;
  clear: none;
}

.device-header h3, h4 {
  margin: 0 4px 0 4px;
  float: left;
}

.device-list {
  margin-top: 5px;
  margin-left: 10px;
  clear: left;
}

.device-list-empty-state {
  float: left;
  margin: 5px;
  font-size: 1.2em;
  font-weight: bold;
}

.device-list-entry {
  float: left;
  width: 98%;
  min-width: 350px;
  max-width: 820px;
  min-height: 215px;
  border: 1px solid #999;
  margin: 5px;
}

.device-list-entry:after {
  clear: both;
}

.device-chart {
  margin-top: 5px;
  float: left;
  width: 100%;
  height: 170px;
  user-select: none;
}

.battery-meter-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  min-width: 78px;
}

.battery-meter {
  margin-left: 2px;
  margin-right: 2px;
}

.battery-meter-header {
  margin-bottom: 4px;
  margin-right: 4px;
  float: left;
}

.battery-meter-cells {
  margin: 0 2px 0 2px;
  padding: 2px;
  width: 40px;
  height: 12px;
  border: 1px solid #999;
  float: left;
  display: flex;
}

.battery-cell-empty {
  margin: 1px 2px 1px 2px;
  background-color: #fff;
  width: 100%;
  height: 10px;
  float: left;
}
.battery-cell-green {
  margin: 1px 2px 1px 2px;
  background-color: lightgreen;
  width: 100%;
  height: 10px;
  float: left;
}
.battery-cell-yellow {
  margin: 1px 2px 1px 2px;
  background-color: #f2cc0c;
  width: 100%;
  height: 10px;
  float: left;
}
.battery-cell-red {
  margin: 1px 2px 1px 2px;
  background-color: #FF6666;
  width: 100%;
  height: 10px;
  float: left;
}

.device-wifi-indicator-container {
  margin-right: 8px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 82px;
}

.device-wifi-indicator-label {
}

.device-wifi-indicator-time {
}

.zoom {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.zoom-label {
  vertical-align: middle;
  font-size: 1.17em;
  font-weight: bold;
  margin-right: 15px;
}

.zoom-dropdown {
  vertical-align: middle;
  min-width: 130px;
}

.zoom-label-refresh {
  vertical-align: middle;
  font-size: 1.10em;
  margin-left: 10px;
}

